import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/james/MedGrocer/mghealth-solutions/src/components/Layout/Layout.js";
import SEO from "layout/SEO";
import Container from "layout/Container";
import VaxOrdering from "components/VaxOrdering";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <Container isCentered fullhd={10} mdxType="Container">
  <VaxOrdering mdxType="VaxOrdering" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      